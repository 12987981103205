var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Papa from 'papaparse';
export const PREVIEW_ROW_COUNT = 5;
// polyfill as implemented in https://github.com/eligrey/Blob.js/blob/master/Blob.js#L653
// (this is for Safari pre v14.1)
function streamForBlob(blob) {
    if (blob.stream) {
        return blob.stream();
    }
    const res = new Response(blob);
    if (res.body) {
        return res.body;
    }
    throw new Error('This browser does not support client-side file reads');
}
// incredibly cheap wrapper exposing a subset of stream.Readable interface just for PapaParse usage
// @todo chunk size
function nodeStreamWrapper(stream, encoding) {
    let dataHandler = null;
    let endHandler = null;
    let errorHandler = null;
    let isStopped = false;
    let pausePromise = null;
    let pauseResolver = null;
    function runReaderPump() {
        return __awaiter(this, void 0, void 0, function* () {
            // ensure this is truly in the next tick after uncorking
            yield Promise.resolve();
            const streamReader = stream.getReader();
            const decoder = new TextDecoder(encoding); // this also strips BOM by default
            try {
                // main reader pump loop
                while (!isStopped) {
                    // perform read from upstream
                    const { done, value } = yield streamReader.read();
                    // wait if we became paused since last data event
                    if (pausePromise) {
                        yield pausePromise;
                    }
                    // check again if stopped and unlistened
                    if (isStopped || !dataHandler || !endHandler) {
                        return;
                    }
                    // final data flush and end notification
                    if (done) {
                        const lastChunkString = decoder.decode(value); // value is empty but pass just in case
                        if (lastChunkString) {
                            dataHandler(lastChunkString);
                        }
                        endHandler(undefined);
                        return;
                    }
                    // otherwise, normal data event after stream-safe decoding
                    const chunkString = decoder.decode(value, { stream: true });
                    dataHandler(chunkString);
                }
            }
            finally {
                // always release the lock
                streamReader.releaseLock();
            }
        });
    }
    const self = {
        // marker properties to make PapaParse think this is a Readable object
        readable: true,
        read() {
            throw new Error('only flowing mode is emulated');
        },
        on(event, callback) {
            switch (event) {
                case 'data':
                    if (dataHandler) {
                        throw new Error('two data handlers not supported');
                    }
                    dataHandler = callback;
                    // flowing state started, run the main pump loop
                    runReaderPump().catch((error) => {
                        if (errorHandler) {
                            errorHandler(error);
                        }
                        else {
                            // rethrow to show error in console
                            throw error;
                        }
                    });
                    return;
                case 'end':
                    if (endHandler) {
                        throw new Error('two end handlers not supported');
                    }
                    endHandler = callback;
                    return;
                case 'error':
                    if (errorHandler) {
                        throw new Error('two error handlers not supported');
                    }
                    errorHandler = callback;
                    return;
            }
            throw new Error('unknown stream shim event: ' + event);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        removeListener(event, callback) {
            // stop and clear everything for simplicity
            isStopped = true;
            dataHandler = null;
            endHandler = null;
            errorHandler = null;
        },
        pause() {
            if (!pausePromise) {
                pausePromise = new Promise((resolve) => {
                    pauseResolver = resolve;
                });
            }
            return self;
        },
        resume() {
            if (pauseResolver) {
                pauseResolver(); // waiting code will proceed in next tick
                pausePromise = null;
                pauseResolver = null;
            }
            return self;
        }
    };
    // pass ourselves off as a real Node stream
    return self;
}
export function parsePreview(file, customConfig) {
    // wrap synchronous errors in promise
    return new Promise((resolve) => {
        let firstChunk = null;
        let firstWarning = undefined;
        const rowAccumulator = [];
        function reportSuccess() {
            // PapaParse normally complains first anyway, but might as well flag it
            if (rowAccumulator.length === 0) {
                return {
                    parseError: new Error('File is empty'),
                    file
                };
            }
            // remember whether this file has only one line
            const isSingleLine = rowAccumulator.length === 1;
            // fill preview with blanks if needed
            while (rowAccumulator.length < PREVIEW_ROW_COUNT) {
                rowAccumulator.push([]);
            }
            resolve({
                file,
                parseError: undefined,
                parseWarning: firstWarning || undefined,
                firstChunk: firstChunk || '',
                firstRows: rowAccumulator,
                isSingleLine
            });
        }
        // use our own multibyte-safe streamer, bail after first chunk
        // (this used to add skipEmptyLines but that was hiding possible parse errors)
        // @todo wait for upstream multibyte fix in PapaParse: https://github.com/mholt/PapaParse/issues/908
        const nodeStream = nodeStreamWrapper(streamForBlob(file), customConfig.encoding || 'utf-8');
        Papa.parse(nodeStream, Object.assign(Object.assign({}, customConfig), { chunkSize: 10000, preview: PREVIEW_ROW_COUNT, error: (error) => {
                resolve({
                    parseError: error,
                    file
                });
            }, beforeFirstChunk: (chunk) => {
                firstChunk = chunk;
            }, chunk: ({ data, errors }, parser) => {
                data.forEach((row) => {
                    const stringRow = row.map((item) => typeof item === 'string' ? item : '');
                    rowAccumulator.push(stringRow);
                });
                if (errors.length > 0 && !firstWarning) {
                    firstWarning = errors[0];
                }
                // finish parsing once we got enough data, otherwise try for more
                // (in some cases PapaParse flushes out last line as separate chunk)
                if (rowAccumulator.length >= PREVIEW_ROW_COUNT) {
                    nodeStream.pause(); // parser does not pause source stream, do it here explicitly
                    parser.abort();
                    reportSuccess();
                }
            }, complete: reportSuccess }));
    }).catch((error) => {
        return {
            parseError: error,
            file
        };
    });
}
export function processFile(input, reportProgress, callback) {
    const { file, hasHeaders, papaParseConfig, fieldAssignments } = input;
    const fieldNames = Object.keys(fieldAssignments);
    // wrap synchronous errors in promise
    return new Promise((resolve, reject) => {
        // skip first line if needed
        let skipLine = hasHeaders;
        let processedCount = 0;
        // use our own multibyte-safe decoding streamer
        // @todo wait for upstream multibyte fix in PapaParse: https://github.com/mholt/PapaParse/issues/908
        const nodeStream = nodeStreamWrapper(streamForBlob(file), papaParseConfig.encoding || 'utf-8');
        Papa.parse(nodeStream, Object.assign(Object.assign({}, papaParseConfig), { chunkSize: papaParseConfig.chunkSize || 10000, error: (error) => {
                reject(error);
            }, chunk: ({ data }, parser) => {
                // pause to wait until the rows are consumed
                nodeStream.pause(); // parser does not pause source stream, do it here explicitly
                parser.pause();
                const skipped = skipLine && data.length > 0;
                const rows = (skipped ? data.slice(1) : data).map((row) => {
                    const stringRow = row.map((item) => typeof item === 'string' ? item : '');
                    const record = {};
                    fieldNames.forEach((fieldName) => {
                        const columnIndex = fieldAssignments[fieldName];
                        if (columnIndex !== undefined) {
                            record[fieldName] = stringRow[columnIndex];
                        }
                    });
                    return record; // @todo look into a more precise setup
                });
                // clear line skip flag if there was anything to skip
                if (skipped) {
                    skipLine = false;
                }
                // info snapshot for processing callback
                const info = {
                    startIndex: processedCount
                };
                processedCount += rows.length;
                // @todo collect errors
                reportProgress(rows.length);
                // wrap sync errors in promise
                // (avoid invoking callback if there are no rows to consume)
                const whenConsumed = new Promise((resolve) => {
                    const result = rows.length ? callback(rows, info) : undefined;
                    // introduce delay to allow a frame render
                    setTimeout(() => resolve(result), 0);
                });
                // unpause parsing when done
                whenConsumed.then(() => {
                    nodeStream.resume();
                    parser.resume();
                }, () => {
                    // @todo collect errors
                    nodeStream.resume();
                    parser.resume();
                });
            }, complete: () => {
                resolve();
            } }));
    });
}
