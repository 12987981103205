/* eslint-disable prettier/prettier */
import React, { useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocale } from '../../locale/LocaleContext';
import * as XLSX from 'xlsx';
const suportedFileList = ['csv', 'json', 'xls', 'xlsx', 'txt'];
import './FileSelector.scss';
export const FileSelector = ({ onSelected, onSelectFile }) => {
    const onSelectedRef = useRef(onSelected);
    onSelectedRef.current = onSelected;
    const handleFileConvert = (file) => {
        if (!file) {
            console.log('No file selected.');
            return;
        }
        const extension = file.name.split('.').pop();
        if (extension == 'csv') {
            onSelectedRef.current(file);
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            var _a, _b;
            try {
                let csv = '';
                if (extension == 'json') {
                    const jsonData = JSON.parse((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
                    const jsonFile = XLSX.utils.json_to_sheet(jsonData);
                    csv = XLSX.utils.sheet_to_csv(jsonFile);
                }
                else {
                    const data = new Uint8Array((_b = e.target) === null || _b === void 0 ? void 0 : _b.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    csv = XLSX.utils.sheet_to_csv(worksheet);
                }
                const csvBlob = new Blob([csv], { type: 'text/csv' });
                const csvFile = new File([csvBlob], `${file.name}.csv`, {
                    type: 'text/csv'
                });
                onSelectedRef.current(csvFile);
            }
            catch (e) {
                console.log(e);
            }
        };
        extension == 'json'
            ? reader.readAsText(file)
            : reader.readAsArrayBuffer(file);
    };
    const l10n = useLocale('fileStep');
    const dropHandler = useCallback((acceptedFiles) => {
        // silently ignore if nothing to do
        if (acceptedFiles.length < 1) {
            return;
        }
        const file = acceptedFiles[0];
        const extentName = file.name.split('.').pop();
        if (!suportedFileList.includes(extentName === null || extentName === void 0 ? void 0 : extentName.toLowerCase())) {
            if (onSelectFile)
                onSelectFile(l10n.fileTypeError);
            return;
        }
        if (onSelectFile)
            onSelectFile('');
        handleFileConvert(file);
        // console.log('File selected:', file);
        // onSelectedRef.current(file);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: dropHandler
    });
    return (React.createElement("div", Object.assign({ className: "CSVImporter_FileSelector", "data-active": !!isDragActive }, getRootProps()),
        React.createElement("input", Object.assign({}, getInputProps())),
        React.createElement("div", { className: "CSVImporter_file_selector" },
            React.createElement("button", { className: "CSVImporter_file_btn" }, "Select the file"),
            React.createElement("span", null, isDragActive
                ? l10n.activeDragDropPrompt
                : l10n.initialDragDropPrompt))));
};
