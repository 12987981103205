import React from 'react';
import './FormatDataRowPreview.scss';
export const FormatDataRowPreview = React.memo(({ hasHeaders, rows }) => {
    const headerRow = hasHeaders ? rows[0] : null;
    const bodyRows = hasHeaders ? rows.slice(1) : rows;
    return (React.createElement("div", { className: "CSVImporter_FormatDataRowPreview" },
        React.createElement("table", { className: "CSVImporter_FormatDataRowPreview__table" },
            headerRow && (React.createElement("thead", null,
                React.createElement("tr", null, headerRow.map((item, itemIndex) => (React.createElement("th", { key: itemIndex }, item)))))),
            React.createElement("tbody", null, bodyRows.map((row, rowIndex) => (React.createElement("tr", { key: rowIndex }, row.map((item, itemIndex) => (React.createElement("td", { key: itemIndex }, item))))))))));
});
